import React from "react";
import { localeBoxLinks } from "../../locales/localeBoxLinks";
import {
  box_links_wrapper,
  box_links_nav,
  link_box,
} from "../styles/modules/box_links.module.css";

function LinksBox({ slug, locale }) {
  const codeLanguage = locale ? locale : "es";
  const boxLinks = localeBoxLinks[codeLanguage];
  const renderLinks = boxLinks.filter(link => link.slug !== slug);

  return (
    <aside className={box_links_wrapper}>
      <nav className={box_links_nav}>
        {renderLinks.map(link => (
          <a href={link.slug} className={link_box} key={link.slug}>
            {link.color ? (
              <div
                className="mini_color"
                style={{ background: link.color }}
              ></div>
            ) : (
              <svg
                height="20"
                viewBox="0 0 24 24"
                width="20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g fill="#494c4e">
                  <path d="m18.94 0c-1.262 0-2.51.47-3.454 1.412l-2.973 2.973c-1.675 1.674-1.852 4.292-.56 6.235 0 .014.006.02.013.028l-1.31 1.313c-.008-.005-.015-.012-.03-.012-.844-.575-1.815-.852-2.787-.86-1.258.008-2.506.477-3.45 1.42l-2.969 2.971c-1.916 1.915-1.888 5.08.063 7.03.985.987 2.29 1.484 3.575 1.49 1.27-.006 2.504-.475 3.448-1.418l2.98-2.98c1.66-1.66 1.86-4.27.59-6.207l1.31-1.326c.846.544 1.81.83 2.775.83 1.265 0 2.506-.478 3.45-1.42l2.98-2.98c1.907-1.91 1.88-5.073-.07-7.024-.998-.993-2.296-1.483-3.581-1.475zm-8.327 14.855c.518 1.1.333 2.448-.546 3.327l-2.974 2.973c-.532.532-1.256.83-2.036.83-.81 0-1.575-.312-2.157-.894-.568-.565-.88-1.33-.895-2.126-.007-.788.298-1.518.837-2.058l2.973-2.973c.532-.532 1.248-.837 2.022-.83.47 0 .922.1 1.333.298l-1.894 1.895c-.397.397-.39 1.028 0 1.42.39.39 1.02.396 1.42 0l1.886-1.888.03.03zm10.55-7.767-2.973 2.972c-.533.534-1.257.83-2.037.83-.453 0-.894-.097-1.29-.282l-.03-.028 2.896-2.895c.39-.39.39-1.028 0-1.42s-1.03-.39-1.42 0l-2.9 2.902c-.194-.404-.293-.844-.3-1.305-.014-.78.29-1.51.83-2.05l2.973-2.974c.525-.54 1.25-.838 2.03-.837.815.01 1.574.328 2.156.896 1.162 1.177 1.2 3.057.062 4.193z" />
                  <path d="m11.953 10.62c0 .015.007.022.014.03l-1.312 1.31c-.007-.006-.014-.013-.028-.013l1.326-1.326z" />
                </g>
              </svg>
            )}

            {link.anchor}
          </a>
        ))}
      </nav>
    </aside>
  );
}

export default LinksBox;
