export const localeBoxLinks = {
  es: [
    { anchor: "paleta de color naranja", slug: "/naranja/", color: "#ff9800" },
    { anchor: "paleta de color marron", slug: "/marron/", color: "#8d4925" },
    { anchor: "paleta de color beige", slug: "/beige/", color: "#e8c39e" },
    {
      anchor: "paleta de color turquesa",
      slug: "/turquesa/",
      color: "#5dc1b9",
    },
    { anchor: "paleta de color cian", slug: "/cian/", color: "#00ffff" },
    {
      anchor: "paleta de color azul eléctrico",
      slug: "/azul/azul-electrico/",
      color: "#014ba0",
    },
    {
      anchor: "paleta de color azul rey",
      slug: "/azul/azul-rey/",
      color: "#0303b5",
    },
    {
      anchor: "paleta de color celeste",
      slug: "/azul/celeste/",
      color: "#0cb7f2",
    },
    {
      anchor: "paleta de color dorado",
      slug: "/amarillo/dorado/",
      color: "#efb810",
    },
    {
      anchor: "Paleta de color jazmín",
      slug: "/amarillo/jazmin/",
      color: "#f8de7e",
    },
    {
      anchor: "Paleta de color mostaza ",
      slug: "/amarillo/mostaza/",
      color: "#f6a700",
    },
    {
      anchor: "paleta de color salmón",
      slug: "/naranja/salmon/",
      color: "#fa8072",
    },
    {
      anchor: "paleta de color coral",
      slug: "/rojo/coral/",
      color: "#e4717a",
    },
    {
      anchor: "paleta de color lila",
      slug: "/morado/lila/",
      color: "#b695C0",
    },
    {
      anchor: "paleta de color malva",
      slug: "/morado/malva/",
      color: "#e0b0ff",
    },
    {
      anchor: "paleta de color lavanda",
      slug: "/morado/lavanda/",
      color: "#b57edc",
    },
    {
      anchor: "paleta de color verde agua",
      slug: "/verde/verde-agua/",
      color: "#03bb85",
    },
    {
      anchor: "paleta de color azul marino",
      slug: "/azul/azul-marino/",
      color: "#2f2c79",
    },
    {
      anchor: "Paleta de color guinda",
      slug: "/rojo/guinda/",
      color: "#952f57",
    },
    {
      anchor: "Paleta de color vino",
      slug: "/rojo/vino/",
      color: "#56070c",
    },
    {
      anchor: "Paletas de color carmesí",
      slug: "/rojo/carmesi/",
      color: "#dc143c",
    },
    {
      anchor: "Paletas de color carmín",
      slug: "/rojo/carmin/",
      color: "#960018",
    },
    {
      anchor: "Paleta de color verde menta",
      slug: "/verde/verde-menta/",
      color: "#9bfab0",
    },
    {
      anchor: "Paleta de color verde esmeralda",
      slug: "/verde/esmeralda/",
      color: "#009d71",
    },
    {
      anchor: "Paleta de color verde militar",
      slug: "/verde/verde-militar/",
      color: "#4B5320",
    },

    {
      anchor: "Paleta de color verde oliva",
      slug: "/verde/oliva/",
      color: "#86895d",
    },
    {
      anchor: "Paleta de color verde manzana",
      slug: "/verde/manzana/",
      color: "#8db600",
    },
    {
      anchor: "Paleta de color índigo",
      slug: "/azul/indigo/",
      color: "#1c4c96",
    },
    {
      anchor: "Paleta de color burdeos",
      slug: "/rojo/burdeos/",
      color: "#96305a",
    },
    {
      anchor: "Paleta de color sepia",
      slug: "/marron/sepia/",
      color: "#aa7444",
    },
    {
      anchor: "Paleta de color nude",
      slug: "/marron/nude/",
      color: "#e5d2c4",
    },
    {
      anchor: "Paleta de color pardo",
      slug: "/marron/pardo/",
      color: "#643c23",
    },
    {
      anchor: "Paleta de color berenjena",
      slug: "/morado/berenjena/",
      color: "#614051",
    },
    {
      anchor: "Paleta de color ocre",
      slug: "/amarillo/ocre/",
      color: "#b9935a",
    },
    {
      anchor: "Paleta de color caqui",
      slug: "/amarillo/caqui/",
      color: "#e0d8b0",
    },
    {
      anchor: "Paleta de color azul pastel",
      slug: "/azul/azul-pastel/",
      color: "#84b6f4",
    },
    {
      anchor: "Paleta de color bronce",
      slug: "/bronce/",
      color: "#CD7F32",
    },
  ],
  en: [
    { anchor: "orange color palette", slug: "/en/orange/", color: "#ff9800" },
    { anchor: "brown color palette", slug: "/en/brown/", color: "#8d4925" },
    { anchor: "beige color palette", slug: "/en/beige/", color: "#e8c39e" },
    {
      anchor: "turquoise palette",
      slug: "/en/turquoise/",
      color: "#5dc1b9",
    },
    { anchor: "cyan color palette", slug: "/en/cyan/", color: "#00ffff" },
    {
      anchor: "electric blue color palette",
      slug: "/en/blue/electric-blue/",
      color: "#014ba0",
    },
    {
      anchor: "royal blue color palette",
      slug: "/en/blue/royal-blue/",
      color: "#0303b5",
    },
    {
      anchor: "sky blue color palette",
      slug: "/en/blue/sky-blue/",
      color: "#0cb7f2",
    },
    {
      anchor: "gold color palette",
      slug: "/en/yellow/gold/",
      color: "#efb810",
    },
    {
      anchor: "jasmine color palette",
      slug: "/en/yellow/jasmine/",
      color: "#f8de7e",
    },
    {
      anchor: "mustard color palette",
      slug: "/en/yellow/mustard/",
      color: "#f6a700",
    },
    {
      anchor: "salmon color palette",
      slug: "/en/orange/salmon/",
      color: "#fa8072",
    },
    {
      anchor: "coral color palette",
      slug: "/en/red/coral/",
      color: "#e4717a",
    },
    {
      anchor: "lilac color palette",
      slug: "/en/purple/lilac/",
      color: "#b695C0",
    },
    {
      anchor: "mauve palette",
      slug: "/en/purple/mauve/",
      color: "#e0b0ff",
    },
    {
      anchor: "lavender color palette",
      slug: "/en/purple/lavender/",
      color: "#b57edc",
    },
    {
      anchor: "aqua green color palette",
      slug: "/en/green/aqua-green/",
      color: "#03bb85",
    },
    {
      anchor: "navy blue color palette",
      slug: "/en/blue/navy-blue/",
      color: "#2f2c79",
    },
    {
      anchor: "Cherry Color Palette",
      slug: "/en/red/cherry/",
      color: "#952f57",
    },
    {
      anchor: "wine color palette",
      slug: "/en/red/wine/",
      color: "#56070c",
    },
    {
      anchor: "crimson color palettes",
      slug: "/en/red/crimson/",
      color: "#dc143c",
    },
    {
      anchor: "carmine color palettes",
      slug: "/en/red/carmine/",
      color: "#960018",
    },
    {
      anchor: "Mint Green Color Palette",
      slug: "/en/green/mint-green/",
      color: "#9bfab0",
    },
    {
      anchor: "Emerald Green Color Palette",
      slug: "/en/green/emerald/",
      color: "#009d71",
    },
    {
      anchor: "pale pink palette",
      slug: "/en/pink/pale-pink/",
      color: "#fdcae1",
    },
    {
      anchor: "olive color palette",
      slug: "/en/green/olive/",
      color: "#86895d",
    },
    {
      anchor: "army green color palette",
      slug: "/en/green/army-green/",
      color: "#4B5320",
    },
    {
      anchor: "Apple Green Color Palette",
      slug: "/en/green/apple/",
      color: "#8db600",
    },
    {
      anchor: "indigo color palette",
      slug: "/en/blue/indigo/",
      color: "#1c4c96",
    },
    {
      anchor: "Bordeaux color palettes",
      slug: "/en/red/bordeaux/",
      color: "#96305a",
    },
    {
      anchor: "Sepia color palette",
      slug: "/en/brown/sepia/",
      color: "#aa7444",
    },
    {
      anchor: "Nude color palette",
      slug: "/en/brown/nude/",
      color: "#e5d2c4",
    },
    {
      anchor: "Taupe color palette",
      slug: "/en/brown/taupe/",
      color: "#643c23",
    },
    {
      anchor: "Eggplant color palette",
      slug: "/en/purple/eggplant/",
      color: "#614051",
    },
    {
      anchor: "Ochre color palette",
      slug: "/en/yellow/ochre/",
      color: "#b9935a",
    },
    {
      anchor: "Khaki color palette",
      slug: "/en/yellow/khaki/",
      color: "#e0d8b0",
    },
    {
      anchor: "bronze color scheme",
      slug: "/en/bronze/",
      color: "#CD7F32",
    },
  ],
  pt: [
    {
      anchor: "paleta de cores laranja",
      slug: "/pt/laranja/",
      color: "#ff9800",
    },
    { anchor: "paleta de cores marrom", slug: "/pt/marrom/", color: "#8d4925" },
    { anchor: "paleta de cores bege", slug: "/pt/bege/", color: "#e8c39e" },
    {
      anchor: "paleta de cores turquesa",
      slug: "/pt/turquesa/",
      color: "#5dc1b9",
    },
    { anchor: "paleta de cores ciano", slug: "/pt/ciano/", color: "#00ffff" },
    {
      anchor: "paleta de cores azul elétrica",
      slug: "/pt/azul/azul-eletrico/",
      color: "#014ba0",
    },
    {
      anchor: "Paleta de cor azul celeste",
      slug: "/pt/azul/celeste/",
      color: "#40cfff",
    },
    {
      anchor: "paleta de cores azul royal",
      slug: "/pt/azul/azul-royal/",
      color: "#0303b5",
    },
    {
      anchor: "paleta de cores azul índigo",
      slug: "/pt/azul/indigo/",
      color: "#1c4c96",
    },
    {
      anchor: "paleta de cor dourado",
      slug: "/pt/amarelo/dourado/",
      color: "#efb810",
    },
    {
      anchor: "Paleta de cor azul marinho ",
      slug: "/pt/azul/azul-marinho/",
      color: "#2f2c79",
    },
    {
      anchor: "Paletas de cores coral",
      slug: "/pt/vermelho/coral/",
      color: "#e4717a",
    },
    {
      anchor: "Paletas de cores salmão",
      slug: "/pt/cor-rosa/salmao/",
      color: "#fa8072",
    },
    {
      anchor: "Paleta de cores lilás",
      slug: "/pt/roxa/lilas/",
      color: "#b695C0",
    },
    {
      anchor: "Paleta de cores malva",
      slug: "/pt/roxa/malva/",
      color: "#e0b0ff",
    },
    {
      anchor: "Paleta de cores lavanda",
      slug: "/pt/roxa/lavanda/",
      color: "#b57edc",
    },
    {
      anchor: "Paleta de cores esmeralda",
      slug: "/pt/verde/esmeralda/",
      color: "#009d71",
    },
    {
      anchor: "Paleta de cores hortelã",
      slug: "/pt/verde/hortela/",
      color: "#9bfab0",
    },
    {
      anchor: "Paleta de cores verde água",
      slug: "/pt/verde/verde-agua/",
      color: "#03bb85",
    },
    {
      anchor: "Paleta de cores verde militar",
      slug: "/pt/verde/verde-militar/",
      color: "#4B5320",
    },
    {
      anchor: "Paleta de cores jasmim",
      slug: "/pt/amarelo/jasmim/",
      color: "#f8de7e",
    },
    {
      anchor: "paleta de cor mostarda",
      slug: "/pt/amarelo/mostarda/",
      color: "#f6a700",
    },
    {
      anchor: "Paleta de cores rosa pastel",
      slug: "/pt/cor-rosa/rosa-pastel/",
      color: "#fdcae1",
    },
    {
      anchor: "Paleta de cores verde oliva",
      slug: "/pt/verde/oliva/",
      color: "#586e26",
    },
    {
      anchor: "Paleta de cores verde maçã",
      slug: "/pt/verde/maca/",
      color: "#89d631",
    },
    {
      anchor: "Paleta de cores carmesim",
      slug: "/pt/vermelho/carmesim/",
      color: "#dc143c",
    },
    {
      anchor: "Paleta de cores cereja",
      slug: "/pt/vermelho/cereja/",
      color: "#952f57",
    },
    {
      anchor: "Paleta de cores vinho",
      slug: "/pt/vermelho/vinho/",
      color: "#56070c",
    },
    {
      anchor: "Paleta de cores carmim",
      slug: "/pt/vermelho/carmim/",
      color: "#960018",
    },
    {
      anchor: "Paleta de cores bordô",
      slug: "/pt/vermelho/bordo/",
      color: "#96305a",
    },
    {
      anchor: "Paleta de cores sépia",
      slug: "/pt/marrom/sepia/",
      color: "#704214",
    },
    {
      anchor: "Paleta de cores pardo",
      slug: "/pt/marrom/pardo/",
      color: "#996b42",
    },
    {
      anchor: "Paleta de cores nude",
      slug: "/pt/marrom/nude/",
      color: "#e5d2c4",
    },
    {
      anchor: "paleta de cores da berinjela",
      slug: "/pt/roxa/berinjela/",
      color: "#614051",
    },
    {
      anchor: "paleta de cor bronze",
      slug: "/pt/bronze/",
      color: "#CD7F32",
    },
  ],
  fr: [
    {
      anchor: "Palettes de couleurs beige",
      slug: "/fr/beige/",
      color: "#e8c39e",
    },
    {
      anchor: "Palettes de couleurs doré",
      slug: "/fr/jaune/doree/",
      color: "#efb810",
    },
    {
      anchor: "Palettes de couleurs orange",
      slug: "/fr/orange/",
      color: "#ff9800",
    },
    {
      anchor: "Palettes de couleurs cyan",
      slug: "/fr/cyan/",
      color: "#00ffff",
    },
    {
      anchor: "Palettes de couleurs turquoise",
      slug: "/fr/turquoise/",
      color: "#5dc1b9",
    },
    {
      anchor: "Palettes de couleurs bleu électrique",
      slug: "/fr/bleu/bleu-electrique/",
      color: "#014ba0",
    },
    {
      anchor: "Palettes de couleurs bleu roi",
      slug: "/fr/bleu/bleu-roi/",
      color: "#0303b5",
    },
    {
      anchor: "Palette de couleur rougue vin",
      slug: "/fr/rouge/vin/",
      color: "#56070c",
    },
    {
      anchor: "Palette de couleur vert menthe",
      slug: "/fr/vert/vert-menthe/",
      color: "#9bfab0",
    },
    {
      anchor: "Palette de couleur vert d'eau",
      slug: "/fr/vert/vert-deau/",
      color: "#03bb85",
    },
    {
      anchor: "Palette de couleur vert militairer",
      slug: "/fr/vert/vert-militaire/",
      color: "#4B5320",
    },
    {
      anchor: "Palette de couleur lilas",
      slug: "/fr/violet/lilas/",
      color: "#b695C0",
    },
    {
      anchor: "Palette de couleur lavande",
      slug: "/fr/violet/lavande/",
      color: "#b57edc",
    },
    {
      anchor: "Palette de couleur mauve",
      slug: "/fr/violet/mauve/",
      color: "#e0b0ff",
    },
    {
      anchor: "Palette de couleur marron",
      slug: "/fr/marron/",
      color: "#8d4925",
    },
    {
      anchor: "Palette de couleu bronze",
      slug: "/fr/bronze/",
      color: "#CD7F32",
    },
  ],
};
