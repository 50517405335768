import React, { useContext } from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { ContextLocale } from "../context/ContextLocale";
import { BasicHeadTags } from "../components/SEO/BasicHeadTags";
import LinksBox from "../components/LinksBox";
// import { allLanguagesLinks } from "../helpers/consts";
// import HrefLang from "../components/SEO/HrefLang";
import { url } from "../../config/website";
// import AdWithPlaceholder from "../components/Ads/AdWithPlaceholder";

function ToolTemplate({ data }) {
  const { setLang } = useContext(ContextLocale);

  const { mdx } = data;
  setLang(mdx.frontmatter.locale ? mdx.frontmatter.locale : "es");

  return (
    <Layout locale={mdx.frontmatter.locale}>
      <MDXRenderer>{mdx.body}</MDXRenderer>

      <LinksBox slug={mdx.frontmatter.slug} locale={mdx.frontmatter.locale} />
    </Layout>
  );
}

export const Head = ({ data }) => {
  const { mdx } = data;
  // const [links] = allLanguagesLinks.tools.filter(elem =>
  //   elem[mdx.frontmatter.locale].includes(mdx.frontmatter.slug)
  // );
  // <HrefLang links={links} />
  return (
    <>
      <BasicHeadTags
        title={mdx.frontmatter.metaTitle}
        description={mdx.frontmatter.metaDescription}
        slug={mdx.frontmatter.slug}
        lang={mdx.frontmatter.locale}
        canonicalUrl={`${url}${mdx.frontmatter.slug}`}
      />
    </>
  );
};

export default ToolTemplate;

export const data = graphql`
  query ($id: String!) {
    mdx(id: { eq: $id }) {
      body
      id
      frontmatter {
        title
        slug
        metaTitle
        metaDescription
        locale
      }
    }
  }
`;
